<template>
  <div class="order-review-container">
    <ModalYesNo
    v-if="isYesno"
    @close="isYesno = false"
     @confirm="postData"
     />
    <ModalSukses v-if="isSukses" />
    <header-back
      rootClassName="rootClassName4"
      text="Back To Order Detail"
    ></header-back>
    <div class="w-full max-xl:px-5">
      <div class="max-w-rifyo mx-auto">
        <div class="order-review-container1">
          <div class="order-review-item-order">
            <div class="order-review-container2">
              <div class="order-review-container3">
                <h1 v-if="!productReview.is_review" class="order-review-text">
                  Give A Review
                </h1>
                <span v-if="!productReview.is_review" class="order-review-text01">
                  <span class="order-review-text02">
                    What do you think about our product and service?
                  </span>
                </span>
                <span class="order-review-text03">
                  <span class="order-review-text04">Product</span>
                </span>
                <product-card-history
                  rootClassName="rootClassName2"
                  :price="`IDR ${formatRupiah(harga)}`"
                  :product="nama"
                  :product_img_src="firstImage(photo)"
                  :object="product"
                ></product-card-history>
                <span class="order-review-text05">
                  <span class="order-review-text06">
                    Your review will appear in the product page of this product.
                  </span>
                </span>
              </div>
              <div class="order-review-container4">
                <div class="order-review-container-label">
                  <span class="order-review-text07">
                    <span class="order-review-text08">Overall Rating</span>
                  </span>
                  <span v-if="!productReview.is_review" class="order-review-text09">
                    <span class="order-review-text10">
                      Please rate our overall product and service. 1 star is
                      worst, 5 star is best.
                    </span>
                  </span>
                </div>
                <div v-if="!productReview.is_review" class="order-review-container-star">
                  <div
                    class="order-review-star-item cursor-pointer"
                    @click="isStar = item"
                    v-for="item in stars"
                    :key="item"
                  >
                    <img
                      :src="
                        isStar && item <= isStar
                          ? '/playground_assets/star-on.svg'
                          : '/playground_assets/star-off.svg'
                      "
                      alt="image"
                      class="order-review-image"
                    />
                  </div>
                </div>
                <div v-else class="order-review-container-star">
                  <div
                    class="order-review-star-item"
                    v-for="item in stars"
                    :key="item"
                  >
                    <img
                      :src="
                        isStar && item <= isStar
                          ? '/playground_assets/star-on.svg'
                          : '/playground_assets/star-off.svg'
                      "
                      alt="image"
                      class="order-review-image"
                    />
                  </div>
                </div>
                <div class="order-review-container-label1">
                  <span class="order-review-text11">
                    <span class="order-review-text12 mb-3">Photos</span>
                  </span>
                  <span v-if="!productReview.is_review" class="order-review-text13">
                    <span class="order-review-text14">
                      You can upload some photos of the product (optional)
                    </span>
                  </span>
                </div>
                <template v-if="!productReview.is_review">
                  <div class="flex gap-2">
                    <div
                      v-if="uploadFile.length < 5"
                      class="my-4 order-review-container5 cursor-pointer"
                      style="height: 74px"
                      @click="addFiles()"
                    >
                      <img
                        src="/playground_assets/plus-photo.svg"
                        alt="image"
                        class="order-review-image5"
                      />
                    </div>
                    <div
                      v-for="item in uploadFile"
                      :key="item"
                      class="relative overflow-hidden">
                      <img
                        alt="image"
                        class="my-4 object-cover"
                        :src="item"
                        style="height: 74px; width: 74px"
                      />
                      <span
                        @click="removeFiles(item)"
                        class="absolute z-20 cursor-pointer top-6 right-2">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="12" fill="white"></circle>
                          <circle cx="12" cy="12" r="11.5" stroke="black" stroke-opacity="0.08"></circle>
                          <g opacity="0.5">
                          <path d="M7.93476 6.90635C7.65077 6.62236 7.19034 6.62236 6.90635 6.90635C6.62236 7.19034 6.62236 7.65077 6.90635 7.93476L10.9688 11.9973L6.90635 16.0598C6.62236 16.3437 6.62236 16.8042 6.90635 17.0882C7.19034 17.3722 7.65077 17.3722 7.93476 17.0882L11.9973 13.0257L16.0598 17.0882C16.3437 17.3722 16.8042 17.3722 17.0882 17.0882C17.3722 16.8042 17.3722 16.3437 17.0882 16.0598L13.0257 11.9973L17.0882 7.93476C17.3722 7.65077 17.3722 7.19034 17.0882 6.90635C16.8042 6.62236 16.3437 6.62236 16.0598 6.90635L11.9973 10.9688L7.93476 6.90635Z" fill="black" fill-opacity="0.9"></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <p class="text-black text-sm mb-5">*Ukuran optimal 74 x 74 pixel dengan format JPG, JPEG, atau PNG. Ukuran file maks 500kb. Jumlah Maks 5 Photo</p>
                </template>
                <template v-else>
                  <div class="flex flex-wrap gap-2">
                    <div
                      class="order-review-photo"
                      v-for="item in productReview.photo"
                      :key="item"
                      >
                        <img
                          :src="item.photo"
                          alt="image"
                          class="photo-product object-cover"
                          style="height: 74px; width: 74px"
                        />
                    </div>
                  </div>
                </template>
                <input-area v-if="!productReview.is_review"
                  v-model="review"
                  rootClassName="rootClassName"
                ></input-area>
                <div v-else class="order-review-container-label1">
                  <span class="order-review-text11">
                    <span class="order-review-text12 mb-3">Your Review</span>
                  </span>
                  <span>" {{productReview.review}} "</span>
                </div>
                <div
                  v-if="!productReview.is_review"
                  class="order-review-btn-login"
                  @click="toggleYesNo()"
                >
                  <span class="order-review-text15">Submit Review</span>
                </div>
                <XClose v-if="isModalError" :text="error_msg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back';
  import ProductCardHistory from '../components/product-card-history';
  import InputArea from '../components/input-area';
  import Footer from '../components/footer';
  import ModalYesNo from '../components/modal-yesno.vue';
  import ModalSukses from '../components/modal-sukses.vue';
  import XClose from '../components/x-close';
  import Api from '../api';
  import axios from 'axios';

  export default {
    name: 'ProductReview',
    props: {},
    components: {
      HeaderBack,
      ProductCardHistory,
      InputArea,
      Footer,
      ModalYesNo,
      ModalSukses,
      XClose,
    },
    data: function () {
      return {
        isYesno: false,
        isSukses: false,
        orderData: {
          created_at: null,
        },
        harga: 0,
        nama: null,
        photo: null,
        stars: [1, 2, 3, 4, 5],
        isStar: 0,
        uploadFile: [],
        review: '',
        isModalError: false,
        error_msg: null,
        reviewData: {
          photo: [],
          rate: 0,
          review: '',
        },
        hasReview: false,
        files: [],
        profile: null,
        product: [],
        productReview: null,
        submitable: true,
        fileWidth: null,
        fileHeight: null,
      };
    },
    computed: {
      profile() {
        return this.$store.state.user;
      },
    },
    async created() {
      this.$root.$on('onClose', () => {
        this.isYesno = !this.isYesno;
      });
      this.$root.$on('onSukses', () => {
        this.postData();
      });
      this.$root.$on('onCloseError', () => {
        this.isModalError = !this.isModalError;
      });
      // fetching data
      await this.getReview();
    },
    methods: {
      async getReview() {
        const reviewData = await axios.get(
          `${Api.productReview}/${this.$route.params.order_id}/${this.$route.params.id}`
        );

        if (reviewData.data.status) {
          this.submitable = true;
          this.productReview = reviewData.data.data;
          this.product= reviewData.data.data.product;
          this.nama = reviewData.data.data.product.title;
          this.harga = reviewData.data.data.product.price;
          this.photo = reviewData.data.data.product.photo;
          this.isStar = reviewData.data.data.rate;
          for (let index = 0; index < reviewData.data.data.photo.length; index++) {
            const element = reviewData.data.photo[index];
            this.uploadFile.push(element.photo);
          }
          this.review = reviewData.data.review;
        }
      },
      async postData() {
        try {
          if (this.isStar === 0) {
            this.error_msg = 'Overall Rating is required';
            this.isModalError = true;
            return false;
          } else if (!this.review) {
            this.error_msg = 'Your Review is required';
            this.isModalError = true;
            return false;
          }

          let formData = new FormData();
          for (const f of this.files) {
            formData.append('photo[]', f);
          }
          formData.append('rate', this.isStar);
          formData.append('review', this.review);
          formData.append('product_id', this.$route.params.id);
          formData.append('order_id', this.$route.params.order_id);
          formData.append('review_id', this.productReview.id);

          const content = await axios.post(Api.productReview, formData );
          if (content.data.status) {
            location.reload();
          } else {
            this.error_msg = content.data.message;
            this.isModalError = true;
          }
        } catch (error) {
          throw error;
        }
      },
      changeFiles(link) {
        var input = document.createElement('input');
        input.type = 'file';
        input.onchange = (event) => {
          var file = event.target.files[0];
          this.files[this.uploadFile.indexOf(link)] = file;
          var url = URL.createObjectURL(event.target.files[0]);
          this.uploadFile[this.uploadFile.indexOf(link)] = url;
          this.uploadFile.push('to be popped');
          this.uploadFile.pop();
        };
        input.click();
      },
      removeFiles(link) {
        this.files.splice(link, 1);
        this.uploadFile.splice(link, 1);
      },
      addFiles() {
        const _SELF = this
        var input = document.createElement('input');
        input.type = 'file';
        input.onchange = (event) => {
          const fileArray = event.target.files[0];
          // const fsize = event.target.files[0].size;
          const size = Math.round((fileArray.size / 1024));
          if (size >= 500) {
            alert(
              "Anda tidak memasukkan Gambar sesuai ukuran yang ditetapkan. Silahkan memasukkan Gambar kembali sesuai ukuran yang ditetapkan");
          } else {
            var reader = new FileReader();
            //Read the contents of Image File.
            reader.readAsDataURL(fileArray);
            reader.onload = function (e) {
              //Initiate the JavaScript Image object.
              var image = new Image();

              //Set the Base64 string return from FileReader as source.
              image.src = e.target.result;

              //Validate the File Height and Width.
              image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 74 || width > 74) {
                  alert("Anda tidak memasukkan Gambar sesuai ukuran yang ditetapkan. Silahkan memasukkan Gambar kembali sesuai ukuran yang ditetapkan");
                } else {
                  // Pengen execute code bawah
                  var file = fileArray;
                  _SELF.files = [..._SELF.files, file];
                  var url = URL.createObjectURL(fileArray);
                  _SELF.uploadFile = [..._SELF.uploadFile, url];
                }
              };
            }
          }
        };
        input.click();
      },
      goPage(page) {
        this.$router.push(page);
      },
      toggleYesNo() {
        this.isYesno = !this.isYesno;
      },
      toggleSukses() {
        this.isSukses = !this.isSukses;
      },
      formatDate() {
        let d = new Date();
        let day = d.getDate();
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        let output =
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          monthNames[d.getMonth()] +
          ' ' +
          d.getFullYear();

        return output;
      },
      formatRupiah(angka) {
        let ang = angka.toString();
        let number_string = ang.replace(/[^,\d]/g, '').toString(),
          split = number_string.split(','),
          sisa = split[0].length % 3,
          rupiah = split[0].substr(0, sisa),
          separator = '',
          ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
          separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah ? rupiah : '';
      },
      firstImage(photo) {
        const myArray = photo.split(",");
        return photo
          ? myArray[0]
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
    },
    watch: {
      isYesno(val) {
        if (val) {
          document.documentElement.style.overflow = 'hidden';
          return;
        }
        document.documentElement.style.overflow = 'auto';
      },
      isSukses(val) {
        if (val) {
          document.documentElement.style.overflow = 'hidden';
          return;
        }
        document.documentElement.style.overflow = 'auto';
      },
    },
  };
</script>

<style scoped>
  .order-review-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
  }
  .order-review-container1 {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .order-review-item-order {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .order-review-container2 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .order-review-container3 {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .order-review-text {
    color: #1f252c;
    font-family: Lora;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-review-text01 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-review-text02 {
    font-family: Lora;
  }
  .order-review-text03 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-review-text04 {
    font-family: Lora;
  }
  .order-review-text05 {
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-family: Lora;
    font-weight: 400;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-review-text06 {
    font-family: Lora;
  }
  .order-review-container4 {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    border-left: 1px solid #aea79d;
    padding-left: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .order-review-container-label {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-review-text07 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-review-text08 {
    font-family: Lora;
  }
  .order-review-text09 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-review-text10 {
    font-family: Lora;
  }
  .order-review-container-star {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
  }
  .order-review-star-item {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-halfunit);
  }
  .order-review-image {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .order-review-star-item1 {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-halfunit);
  }
  .order-review-image1 {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .order-review-star-item2 {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-halfunit);
  }
  .order-review-image2 {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .order-review-star-item3 {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-halfunit);
  }
  .order-review-image3 {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .order-review-star-item4 {
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-halfunit);
  }
  .order-review-image4 {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  .order-review-container-label1 {
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .order-review-text11 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-review-text12 {
    font-family: Lora;
  }
  .order-review-text13 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-review-text14 {
    font-family: Lora;
  }
  .order-review-container5 {
    flex: 0 0 auto;
    width: 72px;
    border: 1px solid #aea79d;
    height: 74px;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .order-review-image5 {
    object-fit: cover;
  }
  .order-review-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .order-review-text15 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  @media (max-width: 991px) {
    .order-review-container1 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .order-review-item-order {
      padding-bottom: 32px;
    }
    .order-review-container2 {
      flex: 1;
      width: auto;
      flex-wrap: wrap;
    }
    .order-review-container3 {
      flex: 100%;
      padding-right: 0px;
    }
    .order-review-container4 {
      flex: 100%;
      border-top: 1px solid #fff;
      margin-top: var(--dl-space-space-oneandhalfunits);
      border-left: 0px;
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-left: 0px;
    }
  }
</style>
